@import "../mixins.css";

.component {

}

.loadingCurtainAppear {
  opacity: 0.01;
}

.loadingCurtainAppearActive {
  opacity: 0.65;
  transition: opacity 300ms linear;
}

.loadingCurtain {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0.65;
  transition: opacity 300ms linear;

  &.fullscreen {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
  }
}

.loadingCurtainSpinner {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 50%;
    height: 50%;
  }
}

.light {
  @mixin background_primary;
}

.medium {
  @mixin background_primary__disabled;
}

.dark {
  @mixin background_alternate;
}