@import "../../typography.css";
@import "../../mixins.css";

.component {
  display: block;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: #f9f6ed;
  background-image: url("https://bnbs-external.imgix.net/evergreen/background-quiz.jpg");
  background-position: center;
  background-size: cover;
}

.top {
  width: 100%;
  z-index: 20;
  position: sticky;
  display: block;

  .progressBar {
    @mixin background_secondary;

    .progress {
      background-color: black;
      height:7px;
      transition-delay:0s;
      transition-duration:0.3s;
      transition-property:width;
      transition-timing-function:ease-in-out;
    }
  }

  .topBar {
    @mixin background_primary;
    position:relative;
    width: 100%;

    .backButton {
      background: white!important;
      -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
      -moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
      box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
      border: none;
      border-radius: 50%;
      cursor:pointer;
      position:absolute;
      z-index: 30;
      width: 50px;
      height: 50px;
      margin-top: 1px;
      @media (--desktop) {
        left: $spacingLg!important;
        top: $spacingSm!important;
      }
      @media (--smartphone-and-tablet) {
        left: $spacingSm!important;
        top: $spacingSm!important;
      }
    }

    .progressText {
      padding: $spacingMd;
      text-align:center;
      @mixin metaTitleText;
    }
  }
}

.middle {
  width: 100%;
  z-index: 10;
  box-sizing:border-box;
  color:rgb(65, 75, 86);
  display:flex;
  text-align:center;
  overflow-y: auto;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.bottom {
  width: 100%;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item {
  width: 48%;
  height: 100px;
  margin-bottom: 2%;
}

.item:nth-child(3n) {
  width: 100%;
}
